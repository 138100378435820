import React from 'react';

class Uberuns extends React.Component {
   

  render() {
    return (
      <div class="continer-fluid p-3">
      <div class='row'>
   <div class="col-md-12"> 
   <h2>Über uns</h2>
   <p>Seit 2008 sind wir nun an diesem Standort – unser Team stammt aus aller Herren Länder, eine Vielfalt, die sich auch in unserer Speisekarte widerspiegelt.</p>
<p>In gemütlicher Kaffeehausatmosphäre bieten wir entspannten workspace mit WLAN und Steckdosen bei (fast) allen Tischen, familienfreundliche Umgebung mit Platz für Kinderwägen und Kinderhochsesseln ebenso wie Barbetrieb mit Cocktails und einer großen Auswahl an Spirituosen zu späterer Stunde.</p>
<p>Neben einer großen Frühstücksauswahl (Di bis Fr bis 15 Uhr, Sa und So bis 17 Uhr) servieren wir à  la carte Gerichte inklusive vegetarischer und veganer Speisen. Wir verwenden hochwertige Zutaten, Bio Eier, Bio-Rapsöl und Bio-Fleisch.</p>
<p>Unser Kaffee ist bio und fair gehandelt von der Kaffeerösterei Alt Wien. Glutenfreies Gebäck sowie mehrere pflanzliche Milchalternativen stehen ebenso zur Verfügung.</p>
<p>Wir vermeiden Fertigprodukte und machen einige unserer Getränke, Tees, Bagels,  Pommes Frites, einen Großteil unserer Kuchen und vieles mehr selbst!</p>
<p  style={{marginBottom:'90px'}}>Wir freuen uns auf Euren Besuch!</p>
  </div> 
  </div>
  </div>
    );
  }
}
export default Uberuns; 