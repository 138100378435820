import React from 'react';
import { fetchResponseWithBody } from '../../restapiutil/RestAPIClient';
import Button from '@mui/material/Button';
import   DatePicker from 'react-datepicker';
//import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { de } from 'date-fns/locale'
import { Backdrop, CircularProgress } from '@mui/material';


class Reservierung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date:new Date(),time:"",name:"",email:"",phone:"",party:"1",terms:false,message:"",open:false};

  // this.state ={date: new Date(), time: '12:22', name: 'Palanisamy',message:"", email: 'palanibe91@gmail.com', phone: '9080738372',terms:true,party:5,open:false};

    this.handleEmail = this.handleEmail.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.handleOpen = this.handleOpen.bind(this);
	this.handleClose = this.handleClose.bind(this);
	//this.registerationObj = this.registerationObj.bind(this);
	
  }
  handleClose () {
	this.setState({open:false});
  }
   handleOpen (){
    this.setState({open:true});
  }
 handleDate(event) {
    console.log(event.target.value);
    this.setState({date: event.target.value});
 } 

 handleDateNew(event) {
    console.log(event);
    this.setState({date: event});
 } 
 handleTime(event) {
    console.log(event.target.value);
    this.setState({time: event.target.value});
 } 
 handleEmail(event) {
  this.setState({email: event.target.value});
}

//  handleChange = (event) => {
//     const name = event.target.name;
//     const value = event.target.value;
//     setInputs(values => ({...values, [name]: value}))
//   }

	getFormatedDate(){
		const today = this.state.date;
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		const formattedToday = dd + '/' + mm + '/' + yyyy;   
		console.log(formattedToday);
		return formattedToday;
	}
  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
	const status = this.validateForm();
	if(!status)return;
	//const list = this.state.date.split("-");
	var regdate =this.getFormatedDate(); //list[2]+"/"+list[1]+"/"+list[0];
	 const obj = {
		"date": regdate, //+" 00:00:00.000",
		"time": this.state.time,
		"partyNo": this.state.party,
		"name": this.state.name,
		"email": this.state.email,
		"phone": this.state.phone,
		"message": this.state.message,
		"shopRefId": "thepointofsale"
	}
	console.log(obj);
	this.handleOpen();
	const data = await this.registerationObj(obj) ;
	this.handleClose();
	console.log(data);
	if(data.responseData){
		this.setState({date:new Date(),time:"",name:"",email:"",phone:"",party:"1",terms:false,message:""});
		alert("Registered successfully");
	}
  }

  registerationObj = async (databody) =>{
    const dataurl = "reserve/register";
	const APPLICATION_JSON = "application/json";
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
    return await fetchResponseWithBody(dataurl,"POST",headers,{},databody);
}

 validateForm =() => {
    // Check if the First Name is an Empty stritteing or not.

    if (this.state.date.length == 0) {
      alert('Bitte Datum eingeben!');
      return false;
    }
	if (this.state.time.length == 0) {
		alert('Bitte Zeit eingeben!');
		return false;
	}
	if (this.state.party.length == 0) {
		alert('Bitte Personenanzahl angeben!');
		return false;
	}
	if (this.state.name.length == 0) {
		alert('Bitte Namen angeben!');
		return false;
	}
	if (this.state.email.length == 0) {
		alert('Bitte Email-Adresse angeben!');
		return false;
	}
	if (this.state.phone.length == 0) {
		alert('Bitte Telefonnummer angeben!');
		return false;
	}
	if (!this.state.terms) {
		alert('Bitte Datenschutzerklärung annehmen!');
		return false;
	}
	return true;
}



  render() {
    return (
      <div class="continer-fluid p-3">
        <div class='row'>
     <div class="col-md-12"> 
      <h2>RESERVIERUNG</h2>
      {/* <h5>RESERVIERUNG</h5> */}
      <form className="form-horizontal col-md-6 col-xs-12 col-sm-8"  onSubmit={this.handleSubmit}>
      <fieldset class="scheduler-border">
      <legend class="scheduler-border">Tisch reservieren</legend>
      <div className="form-group">
	 
        <label className="col-md-4 control-label" for="date">Datum</label>  
        <div className="col-md-12">
		<DatePicker
  selected={this.state.date}
  showIcon
  closeOnScroll={true}
  minDate={new Date()}
 	//maxDate={addMonths(new Date(), 5)}
  onChange={(date) => this.handleDateNew(date)}
  placeholderText="Click to select a date"
  dateFormat="dd/MM/yyyy"
  
  locale={de}
  //className="form-control input-md"
/>
		{/* <input id="date" name="text"  value={this.state.date}  type="date" placeholder="Date" onChange={this.handleDate} className="form-control input-md"/> */}

        {/* <input  id="datepicker" name="text"  value={this.state.date}  type="text" placeholder="dd/mm/yy" onChange={this.handleDate} className="form-control input-md"/> */}
        </div>
      </div>
      <div className="form-group">
        <label className="col-md-4 control-label" for="textinput">Zeit</label>  
        <div className="col-md-12"> 
		<select name="time"   type="time" value={this.state.time}  onChange={this.handleTime} placeholder="Zeit" className="form-control input-md">
		<option value="08:30">08:30</option>
		<option value="09:00">09:00</option>
		<option value="09:30">09:30</option>
		<option value="10:00">10:00</option>
		<option value="10:30">10:30</option>
		<option value="11:00">11:00</option>
		<option value="11:30">11:30</option>
		<option value="12:00">12:00</option>
		<option value="12:30">12:30</option>
		<option value="13:00">13:00</option>
		<option value="13:30">13:30</option>
		<option value="14:00">14:00</option>
		<option value="14:30">14:30</option>
		<option value="15:00">15:00</option>
		<option value="15:30">15:30</option>
		<option value="16:00">16:00</option>
		<option value="16:30">16:30</option>
		<option value="17:00">17:00</option>
		<option value="17:30">17:30</option>
		<option value="18:00">18:00</option>
		<option value="18:30">18:30</option>
		<option value="19:00">19:00</option>
		<option value="19:30">19:30</option>
		<option value="20:00">20:00</option>
		<option value="20:30">20:30</option>
		<option value="21:00">21:00</option>
		</select>
        </div>
      </div>
      <div class="form-group">
        <label className="col-md-4 control-label" for="textinput">Anzahl der Personen</label>  
        <div className="col-md-12">
        <select name="rtb-party" id="rtb-party" value={this.state.party} onChange={(e) => this.setState({party: e.target.value}) }  class="form-control" required="" aria-required="true" data-selected="">
									<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						<option value="8">8</option>
						<option value="9">9</option>
						<option value="10">10</option>
						<option value="11">11</option>
						<option value="12">12</option>
						<option value="13">13</option>
						<option value="14">14</option>
						<option value="15">15</option>
						<option value="16">16</option>
						<option value="17">17</option>
						<option value="18">18</option>
						<option value="19">19</option>
						<option value="20">20</option>
						
					</select>
        </div>
      </div> 
      </fieldset>
      <fieldset class="scheduler-border">
        <legend class="scheduler-border">Kontaktdaten</legend>
        <div className="form-group">
          <label className="col-md-4 control-label" for="textinput">Name</label>  
          <div className="col-md-12">
          <input id="textinput" name="name"  value={this.state.name}  onChange={(e) => this.setState({name: e.target.value}) } 
          type="text" placeholder="Name" className="form-control input-md"/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-4 control-label" for="textinput">Email</label>  
          <div className="col-md-12">
          <input id="textinput" name="email"   value={this.state.email}  onChange={(e) => this.setState({email: e.target.value}) } 
		   	type="email" placeholder="Email" className="form-control input-md"/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-4 control-label" for="textinput">Telefonnummer</label>  
          <div className="col-md-12">
          <input id="textinput" name="phone"  value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value}) }  type="number" placeholder="Telefonnummer" className="form-control input-md"/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-4 control-label" for="textinput">Nachricht</label>  
          <div className="col-md-12">
          <textarea  name="message"    type="text" value={this.state.message} onChange={(e) => this.setState({message: e.target.value}) }  placeholder="Nachricht" className="form-control input-md"></textarea>
          </div>
        </div>
      </fieldset>
      <fieldset class="scheduler-border">
        {/* <legend class="scheduler-border w-100">Contact Details</legend> */}
        <input type='checkbox' checked={this.state.terms}  onChange={(e) => this.setState({terms: !this.state.terms}) } /> Ich akzeptiere die Datenschutzbestimmungen und bin mit der Verarbeitung meiner Daten einverstanden. <a href='/privacy'>Datenschutzerklärung</a>
        </fieldset>
		<br/>
      <fieldset> 
	  
        <Button variant="contained" style={{margin: '0 auto', display: "flex"}} onClick={this.handleSubmit}>jetzt reservieren</Button>
		
        </fieldset>
		
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.open}
        // onClick={this.handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      </div>
      </div><br/><br/><br/><br/>
      </div>
	  
    );
  }
}
export default Reservierung; 
