import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
function Kulinarische (props) {
   


    return <> 
    <embed src={props.menuurl+"#toolbar=0"} width="100%" height="1300px" type="application/pdf"></embed>		

        </>    ;

}
export default Kulinarische; 