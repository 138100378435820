import React, { useState } from 'react';
import { fetchResponseWithBody } from '../../restapiutil/RestAPIClient';
import Button from '@mui/material/Button';
//import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Radio, RadioGroup, Snackbar } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { right } from '@popperjs/core';
import DeleteIcon from '@mui/icons-material/Delete';


class Lottory extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {masterid:"",number:5, combination:"3",inputFields:[{index: 1, value: "3"},{index: 2, value: "5"},{index: 3, value: "7"},{index: 4, value: "14"},{index: 5, value: "17"}]
    //       ,lottories:[],
    //       name:"Test",master:[]
    //       ,successnumbers:[{index: 1, value: "3"},{index: 2, value: "5"},{index: 3, value: "7"},{index: 4, value: "10"},{index: 5, value: "12"},{index: 6, value: "25"},{index: 7, value: "27"}]
    //   };

      this.state = {masterid:"",number:1, combination:"1",inputFields:[]
      ,lottories:[],snackbaropen:false,snackbardata:"",recordsperpage:10,page:0,totalrecords:0,
      name:"",master:[],
      selectedcategory:{
        A: true,
        B: true,
        C: true,
        D: true,
        E:true
      },filermatching:0
      ,successnumbers:[{index: 1, value: ""},{index: 2, value: ""},{index: 3, value: ""},{index: 4, value: ""},{index: 5, value: ""},{index: 6, value: ""}]
      ,znumber:""
  };

    

  // this.state ={date: new Date(), time: '12:22', name: 'Palanisamy',message:"", email: 'palanibe91@gmail.com', phone: '9080738372',terms:true,party:5,open:false};

   
    this.handleNumber = this.handleNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.handleOpen = this.handleOpen.bind(this);
	this.handleClose = this.handleClose.bind(this);
  this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  this.handleChangePage = this.handleChangePage.bind(this);
  this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  this.handleSelectedCateoryChange = this.handleSelectedCateoryChange.bind(this);
  this.loadDetails = this.loadDetails.bind(this);
	this.saveDetails = this.saveDetails.bind(this);
  this.saveDetail = this.saveDetail.bind(this);
  this.getMaster().then(data=>this.setState({
    master :data.responseData
  }));
	
  }
  handleClose () {
	this.setState({open:false});
  }
   handleOpen (){
    this.setState({open:true});
  }

   handleSnackbarClose = () => {
    this.setState({snackbaropen: false });
  };


   handleChangePage = (event, newPage) => {
    this.setState({page: newPage });
  console.log(newPage)
  setTimeout(() => {
    this.loadDetails(event,newPage);
  }, 100);
   
  };

   handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(parseInt(event.target.value, 10));
    this.setState({page:0,recordsperpage:parseInt(event.target.value, 10)});
    setTimeout(() => {
      this.loadDetails(event,0);
    }, 100);
   // setPage(0);
  };

  handleSelectedCateoryChange = (event) =>{
   this.state.selectedcategory[event.target.name]=event.target.checked;
   //alert(JSON.stringify(this.state.selectedcategory));
   this.setState({selectedcategory:this.state.selectedcategory});
    
  }
 handleNumber(event) {
    console.log(event.target.value);
    var inputField=[];
    for(var i=0;i<event.target.value;i++){
      var value ='';
      if(this.state.inputFields.length>i){
          value=this.state.inputFields[i].value;
      }
      inputField.push({index: i+1, value: value});
    }
    this.setState({number: event.target.value,inputFields:inputField});
 } 


	
  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
	const status = this.validateForm();
	if(!status)return;
  if(this.state.masterid!=""){
    var v= window.confirm("Do you want to overwrite the data?. If yes the previous data will be erased.");
    if(!v){
      return;
    }
  }

	 const obj = {
		"numbers": this.state.inputFields.map(obj => obj.value),
    "successNumbers": this.state.successnumbers.map(obj => (obj.value=="")?"0":obj.value),
    "name":this.state.name,
		"combination": this.state.combination,
    "masterid":this.state.masterid,
		"shopRefId": "thepointofsale"
	}
	console.log(obj);
	this.handleOpen();
	const data = await this.getNumbers(obj) ;
	this.handleClose();
	console.log(data);
	if(data.responseData){
		//this.setState({date:new Date(),time:"",name:"",email:"",phone:"",party:"1",terms:false,message:""});
	//	this.setState({lottories: data.responseData});
  
  this.setMaster(data.responseData);
    //alert("Saved successfully");
    this.state.master.push(data.responseData);
    this.setState({master:this.state.master,snackbaropen:true,snackbardata:"Saved successfully"});
	}
  }

  async loadDetails(event,newPage) {
    if(event!=null)
    event.preventDefault();
    console.log(this.state);
	if(this.state.masterid==""){
    alert("Invalid input");
    return;
  }
  
  
	this.handleOpen();
  this.setState({lottories:[]});
  console.log("newPage" +newPage+" "+this.state.page);
	const data = await this.getDetails() ;
	this.handleClose();
	console.log(data);
	if(data.responseData){
		//this.setState({date:new Date(),time:"",name:"",email:"",phone:"",party:"1",terms:false,message:""});
		this.setState({lottories: data.responseData.content,snackbaropen:true,snackbardata:"Loaded successfully",
      totalrecords:data.responseData.totalElements
  });
    //alert("Loaded successfully");
	}
  }

  async saveDetails(event) {
    event.preventDefault();
    console.log(this.state);
	if(this.state.masterid==""){
    alert("Invalid input");
    return;
  }
  var list = this.state.lottories.filter(obj=>obj.changed==true).map((obj,i)=>{return {id:obj.id,category:obj.category}});

	alert("Number Of Records Changed "+list.length);
  if(list.length==0){
    this.setState({snackbaropen:true,snackbardata:"Nothing to save"});
    return;
  }

	this.handleOpen();
	const data = await this.updateDetails(list) ;
	this.handleClose();
	// console.log(data);
	if(data.responseData){
		this.setState({lottories: data.responseData.content});
    this.setState({lottories: data.responseData.content,snackbaropen:true,snackbardata:"Saved successfully"});
	}
  }

  async saveDetail(obj) {
   
  var list = {id:obj.id,category:obj.category};

	

	//this.handleOpen();
	const data = await this.updateDetails([list]) ;
	//this.handleClose();
	// console.log(data);
	if(data.responseData){
		this.setState({lottories: data.responseData.content});
    this.setState({lottories: data.responseData.content,snackbaropen:true,snackbardata:"Saved successfully"});
	}
  }

  deleteMaster = async (item) =>{
    var conf = window.confirm("Do You want to Delete "+item.name+"?");
    if(conf){
      var conf = window.confirm("Confirm Delete "+item.name+"?");
      if(conf){
        this.handleOpen();
          const data = await  this.deleteMasterFromDB(item);
          this.handleClose();
          console.log(data);
          if(data.responseData){
            alert("Deleted Succesfully");
            this.getMaster().then(data=>this.setState({
              master :data.responseData
            }));
          }
      }
    }
  }

  setMaster = (item)=>{
    this.setState(
      {number:item.numbers.length,
      inputFields:item.numbers.map((obj,i)=> { return{index: i+1, value: obj}}),
       name:item.name,
       combination:item.combination,
     //  successnumbers:item.successNumbers.map((obj,i)=> { return{index: i+1, value: obj}}),
       masterid:item.id
       }
      );
      setTimeout(() => {
        this.loadDetails();
      }, 100); 
  }


  setMasterEmpty = ()=>{
    this.setState(
      {masterid:"",number:1, combination:"1",inputFields:[]
      ,lottories:[],snackbaropen:false,snackbardata:"",recordsperpage:10,page:0,totalrecords:0,
      name:""
   //   ,successnumbers:[{index: 1, value: ""},{index: 2, value: ""},{index: 3, value: ""},{index: 4, value: ""},{index: 5, value: ""},{index: 6, value: ""},{index: 7, value: ""}]
  }
      ) 
  }

  getMaster = async () =>{
    const dataurl = "lottory/getmaster";
	const APPLICATION_JSON = "application/json";
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
    return await fetchResponseWithBody(dataurl,"GET",headers,{},{});
}

deleteMasterFromDB = async (item) =>{
  const dataurl = "lottory/delete/"+item.id;
const APPLICATION_JSON = "application/json";
  const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
  return await fetchResponseWithBody(dataurl,"DELETE",headers,{},{});
}


getNumbers = async (databody) =>{
  const dataurl = "lottory/getnumber";
const APPLICATION_JSON = "application/json";
  const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
  return await fetchResponseWithBody(dataurl,"POST",headers,{},databody);
}
getCatgory = ()=>{
  var cat = [];
  var selcat = this.state.selectedcategory;
  if(selcat.A){
    cat.push("A");
  }
  if(selcat.B){
    cat.push("B");
  }
  if(selcat.C){
    cat.push("C");
  }
  if(selcat.D){
    cat.push("D");
  }
  if(selcat.E){
    cat.push("E");
  }
  return cat;
}

getDetails = async (category) =>{
  const dataurl = "lottory/getdetail/"+this.state.masterid+"?page="+this.state.page+"&recordsperpage="+this.state.recordsperpage+"&category="+this.getCatgory().join();
const APPLICATION_JSON = "application/json";
  const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
  return await fetchResponseWithBody(dataurl,"GET",headers,{},{});
}
updateDetails = async (databody) =>{
  const dataurl = "lottory/updatedetail/"+this.state.masterid+"?page="+this.state.page+"&recordsperpage="+this.state.recordsperpage+"&category="+this.getCatgory().join();;
const APPLICATION_JSON = "application/json";
  const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
  return await fetchResponseWithBody(dataurl,"POST",headers,{},databody);
}


 validateForm =() => {
    // Check if the First Name is an Empty stritteing or not.

    if (this.state.inputFields.length == 0) {
      alert('Enter Number of Values');
      return false;
    }
	if (this.state.combination <= 1) {
		alert('Enter Combination!');
		return false;
	}
	
	return true;
}



   render() {
    var disableinput = this.state.masterid==""?false:true;
    var list = this.state.lottories;
    if(this.state.filermatching>0){
      list =list.filter(input => this.state.successnumbers.filter( ai => input.numbers.includes(parseInt(ai.value+"")) ).length==this.state.filermatching);

    }

    var match1 = 0;
    var match2 = 0;
    var match3 = 0;
    var match4 = 0;
    var match5 = 0;
    var match6 = 0;
    var match0z = 0;
    var match1z = 0;
    var match2z = 0;
    var match3z = 0;
    var match4z = 0;
    var match5z = 0;
    var match6z = 0;
    
    return (
      <div class="continer-fluid p-3">
        <div class='row' >
     <div class="col-md-12"> 
      <h2>Lottory</h2>

<h4>Saved Values</h4>
      <Grid container spacing={2}>
      {this.state.master.map((item,index)=>{
        return (
<Grid item xs={4} md={2}>
  <div style={{border:"1px solid red"}}>
  <Button style={{backgroundColor:"white"}} onClick={(e)=>{ this.setMaster(item);}}>{item.name}</Button>
    <IconButton aria-label="delete" size="large" color="error" onClick={(e)=> {this.deleteMaster(item);}}> 
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  </div >
   
  </Grid>
        );
      })} 
      <Grid item xs={4} md={2}>
       <Button style={{backgroundColor:"white"}} onClick={(e)=>{ this.setMasterEmpty();}}>Create New</Button>
       </Grid>
</Grid>

      <form className="form-horizontal col-md-6 col-xs-12 col-sm-8"  onSubmit={this.handleSubmit}>
      <fieldset class="scheduler-border">
      <legend class="scheduler-border">Number Generation</legend>
      
      <div className="form-group">
       <div>{this.state.masterid!="" && <span>Unique Identifier {this.state.masterid}</span>} </div>
        <label className="col-md-4 control-label" for="textinput">Number of Values</label>  
        <div className="col-md-12"> 
		<select name="number" disabled={disableinput}   value={this.state.number}  onChange={this.handleNumber} placeholder="Number" className="form-control input-md">
		<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						<option value="8">8</option>
						<option value="9">9</option>
						<option value="10">10</option>
						<option value="11">11</option>
						<option value="12">12</option>
						<option value="13">13</option>
						<option value="14">14</option>
						<option value="15">15</option>
						<option value="16">16</option>
						<option value="17">17</option>
		</select>
        </div>
      </div>
      <div className="form-group">
      {this.state.inputFields.map((input, index) => {
          return (
           
              <input key={index} style={{width:"50px"}} disabled={disableinput}
                name={input.index}
                placeholder={input.value}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
              }}
                onChange={(e) => {
                  this.state.inputFields[input.index-1].value= e.target.value;
                }
                }
              />
              
            
          )
        })}
        </div>
      <div class="form-group">
        <label className="col-md-4 control-label" for="textinput">Combination Set</label>  
        <div className="col-md-12">
        <select name="rtb-party" id="rtb-party" value={this.state.combination} onChange={(e) => this.setState({combination: e.target.value}) } disabled={disableinput} class="form-control" required="" aria-required="true" data-selected="">
									<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						
						
					</select>
        </div>
      </div> 
      <div className="form-group">
          <label className="col-md-4 control-label" for="textinput">Name</label>  
          <div className="col-md-12">
          <input id="textinput" name="name"  value={this.state.name}  onChange={(e) => this.setState({name: e.target.value}) } disabled={disableinput}
          type="text" placeholder="Name" className="form-control input-md"/>
          </div>
        </div>
      <div className="form-group">
          <h6>Enter Winning Numbers</h6>
          {this.state.successnumbers.map((input, index) => {
          return (

             <input key={index} style={{width:"50px"}}
                name={input.index}
                placeholder={input.value}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
              }}
                onChange={(e) => {
                  this.state.successnumbers[input.index-1].value= e.target.value;
                  this.setState({successnumbers: this.state.successnumbers});
                }
                }
              />
            
          )
        })
        
        
       
        
        }
          ZZ : <input  style={{width:"50px",backgroundColor:"yellow"}}
             placeholder={this.state.znumber}
             onKeyPress={(event) => {
               if (!/[0-9]/.test(event.key)) {
                   event.preventDefault();
               }
           }}
             onChange={(e) => {
              this.setState({znumber: e.target.value});
             }
             }
           />
      </div>
       {this.state.masterid=="" && <Button variant="contained" style={{margin: '0 auto', display: "flex"}} onClick={this.handleSubmit}>Save Input Values</Button>}
      </fieldset>
     
		<br/>
    {this.state.masterid!="" &&<fieldset> 
      <FormGroup row>
  <FormControlLabel control={<Checkbox checked={this.state.selectedcategory.A} onChange={this.handleSelectedCateoryChange} name="A" color="secondary"/>} label="A" />
  <FormControlLabel  control={<Checkbox checked={this.state.selectedcategory.B}  onChange={this.handleSelectedCateoryChange} name="B" color="secondary"/>} label="B" />
  <FormControlLabel  control={<Checkbox checked={this.state.selectedcategory.C}  onChange={this.handleSelectedCateoryChange} name="C" color="secondary"/>} label="C" />
  <FormControlLabel  control={<Checkbox checked={this.state.selectedcategory.D}  onChange={this.handleSelectedCateoryChange} name="D" color="secondary"/>} label="D" />
  <FormControlLabel  control={<Checkbox checked={this.state.selectedcategory.E}  onChange={this.handleSelectedCateoryChange} name="E" color="secondary"/>} label="E" />

</FormGroup>
<label for="party">Enter Matching filter</label>
<select name="party" id="party" value={this.state.filermatching} onChange={(e) => this.setState({filermatching: e.target.value}) } class="form-control" required="" aria-required="true" data-selected="">
        <option value="0">ALL</option>
		    <option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
					</select>
          
	  
      <Button variant="contained" style={{margin: '0 auto', display: "flex"}} onClick={this.loadDetails}>Load Data</Button>
		
        </fieldset>}
		
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.state.open}
        // onClick={this.handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </form>
      
      <div className="form-group">
        { <h1>No of Records Displaying : {list.length} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Records :  {this.state.totalrecords}</h1> 
        
        }
        <TablePagination color="primary" style={{backgroundColor:"white"}}
      component="div"
      count={this.state.totalrecords}
      page={this.state.page}
      onPageChange={this.handleChangePage}
      rowsPerPage={this.state.recordsperpage}
      onRowsPerPageChange={this.handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100,200,500,2000,5000,10000]}
    />
    <div style={{float:right, width : "1000px"}}>
      {
        
        
        list.map((input, index) => {
          var m = this.state.successnumbers.filter( ai => input.numbers.includes(parseInt(ai.value+"")) ).length;
          var n = input.numbers.filter( ai => parseInt(this.state.znumber) == parseInt(ai) ).length;
          if(n>0){
            switch(m){
              case 0:
              match0z++;
              break; 
              case 1:
              match1z++;
              break;
              case 2: 
                match2z++;
                break;
              case 3:
                match3z++; 
                break;
              case 4:
                match4z++; 
                break;
              case 5:
                match5z++; 
                break;
              case 6:
                match6z++; 
                break;
                                
            }
          }else{
            switch(m){
              case 1:
              match1++;
              break;
              case 2: 
                match2++;
                break;
              case 3:
                match3++; 
                break;
              case 4:
                match4++; 
                break;
              case 5:
                match5++; 
                break;
              case 6:
                match6++; 
                break;
                                
            }
          }
          
          return (
           
            <div key={index} style={{float:"left",width:"100%",border: "green solid 2px","padding":"5px",marginTop:"5px"}}>
              <div style={{float:"left"}}>
              <span  style={{width:"50px",color: "white",margin:"5px","padding":"5px",display:"inline-grid"}}> 
              {(this.state.page*this.state.recordsperpage)+ index+1}
              </span>
              <span  style={{width:"50px",border: "green solid 2px",color: "white",margin:"5px","padding":"5px",display:"inline-grid"}}> 
              { m
              }
              </span>
             { input.numbers.map((element,i)=>{
                var color = "white";
                if( parseInt(this.state.znumber) == parseInt(element) ){
                  color = "yellow";
                }else if(this.state.successnumbers.map(el=> el.value+"").includes(element+"")){
                  color="green";
                }
                return (

              <span  style={{width:"50px",backgroundColor:color,margin:"5px","padding":"5px",display:"inline-grid"}}
              key={i} > {element}</span>
                )
              })}
            </div>


              <FormGroup aria-label="position" row style={{float:"left"}}>
              <RadioGroup row  defaultValue={input.category}  onChange={
                (e) => {
                  input.category=e.target.value; input.changed=true;
                  this.saveDetail(input);
                }
               
              } 
        aria-labelledby="demo-row-radio-buttons-group-label">
                <FormControlLabel value="A" control={<Radio  defaultChecked />} label="A" />
                <FormControlLabel  value="B" control={<Radio  />} label="B" />
                <FormControlLabel  value="C" control={<Radio  />} label="C" />
                <FormControlLabel  value="D" control={<Radio  />} label="D" />
                <FormControlLabel  value="E" control={<Radio  />} label="E" />
                </RadioGroup>
              </FormGroup>
              
              </div>
              
              
            
          )
        })}
        </div>
 { <div style={{float : "left", width:"450px"}}>
  <h4><span>Zero Match +ZZ : {match0z}</span></h4> 
  <h4><span>One Match : {match1}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>One Match +ZZ : {match1z}</span></h4>
  <h4><span>Two Match : {match2}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Two Match +ZZ : {match2z}</span></h4>
  <h4><span>Three Match : {match3}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Three Match +ZZ : {match3z}</span></h4>
  <h4><span>Four Match : {match4}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Four Match +ZZ : {match4z}</span></h4>
  <h4><span>Five Match : {match5}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Five Match +ZZ : {match5z}</span></h4>
  <h4><span>Six Match : {match6}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>Six Match +ZZ : {match6z}</span></h4> </div>
        }
{/* {(this.state.lottories.length>0)?<Button variant="contained" style={{margin: '0 auto', display: "flex","position":"static"}} onClick={this.saveDetails}>Save Data</Button>:""} */}
      
      
        </div>
      </div>
      </div><br/><br/><br/><br/>
      <Snackbar
  anchorOrigin={{ "vertical":"center", "horizontal":"center" }}
  open={this.state.snackbaropen}
  onClose={this.handleSnackbarClose}
  message={this.state.snackbardata}
  key={"top" + "right"}
  autoHideDuration={5000}
/>
{/* {(this.state.lottories.length>0)?<Button variant="contained" style={{margin: '0 auto', display: "flex","position":"absolute"}} onClick={this.saveDetails}>Save Data</Button>:""} */}

      </div>
	  
    );
  }
}
export default Lottory; 
